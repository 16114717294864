import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const WriteForMePage = () => (
  <Layout
    pageWrapperClassName="for-sale-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Essays for Sale to Help You Lighten Your Academic Burden | TakeAwayEssay.com"
      meta={[
        {
          name: 'description',
          content: 'Essays for sale give you great opportunities to make your college or university years less stressful. By having papers of excellent quality, you will improve your academic performance and get more free time for other activities by improving your writing skills. To learn how to make things work, keep reading!',
        },

        {
          property: 'og:title',
          content: 'Essays for Sale to Help You Lighten Your Academic Burden | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'Essays for sale give you great opportunities to make your college or university years less stressful. By having papers of excellent quality, you will improve your academic performance and get more free time for other activities by improving your writing skills. To learn how to make things work, keep reading!',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/for-sale.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Want to buy a personalized essay written
        <br className="first-screen-section__title__br" />
        {' '}
        according to your instructions?
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        No need to delay, as we are here
        <br className="first-screen-section__heading__br" />
        {' '}
        for you 24/7
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Essays for sale to make your academic life easier
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            <strong>
              During your years in college or university, you find yourself bombarded with academic
              tasks of different types and complexity. Eventually, you won’t be able to succeed
              without getting help from essays for sale.
            </strong>
          </p>
          <p>
            By choosing a credible custom essay writing service like TakeAwayEssay.com, you can get
            quality academic assistance. Whether it comes to argumentative essays, dissertation
            chapters, research papers, personal statements, and movie or book reviews, we cover you
            on that. If this doesn’t sound convincing to you, you can find more information down
            there.
          </p>
          <h2 className="h3">Reasons to choose essays for sale online</h2>
          <p>
            Using one of the research paper writing services can be rewarding. You will easily get
            access to multiple essays for sale. In fact, there are many reasons for that, including:
          </p>
          <ul className="dot-list">
            <li>
              You get stuck with an essay, or don't know how to proceed with any other academic task
            </li>
            <li>You look for efficient instructions with writing assignments</li>
            <li>
              You don’t have much time to complete all the academic routine and need help ith it
            </li>
            <li>You want to check the paper formatting, spelling, and grammar</li>
            <li>Your need some paper samples as a proper basis for further work</li>
          </ul>
          <p>
            Professional writers from TakeAwayEssay.com can provide you with any type of paper for
            purchase. By purchasing custom essays for sale, you can make your academic life less
            stressful. You won’t have to worry about tight deadlines, tons of resources to read
            through, and sleepless nights. If you decide to use professional assistance when working
            on your papers, you will soon realize that even writing references can take much longer
            than you expect but fortunately you’re given a helping hand. Your attempts to deal with
            an academic burden on your own are impressive, but sometimes it doesn’t affect the grade
            you receive. This is where you should be careful and act in a smart way!
          </p>
          <h2 className="h3">Finding essay papers for sale</h2>
          <p>
            When you start looking for essay papers for sale, you will use a standard phrase like:
            “complete my task.” However, if you want to get professional assistance, it's not enough
            just to send your request. To buy the best essays for sale at TakeAwayEssay.com, you
            will want to make sure that it is actually good. How to check the quality of this
            writing agency? Have a look at our offers:
          </p>
          <ul className="dot-list">
            <li>
              Fast delivery. Within the requested timeframes, you will receive high-quality papers.
              Our efficient and safe service accepts tight deadlines, which can be between 12- 24
              hours to complete your order and even less.
            </li>
            <li>
              Authentic custom-written assignments. Even though we offer essays for sale online, it
              doesn’t mean that we sell cheap samples used by other customers. Every paper is devoid
              of plagiarism and crafted according to your instructions. There is a way to check this
              out. While completing an online order, you can specify a discipline, complexity level,
              type of paper, subject matter, the timeframes, and your personal guidelines.
            </li>
            <li>
              Qualified writers. The fact that you can choose a writer yourself and discuss the
              important nuances of the order with him or her point out at our credibility. Pay
              attention to the fact that we give you a chance to communicate with the assigned
              writer until your work is completed.
            </li>
            <li>
              Free revision and refund. If the final paper doesn’t meet your initial requirements,
              you can request a free revision. If you don’t want to give a second chance to the
              assigned writer, apply for a reimbursement. You pay for quality content and we are
              obliged to provide you with it!
            </li>
          </ul>
          <h2 className="h3">Getting the best essays for sale</h2>
          <p>
            Before you take action, you may doubt if it's legal to get essays for sale online. Think
            of it this way, sample papers can be applied as a proper writing model to follow. This
            approach can hardly be criticized. Thus, you risk nothing when you order this kind of
            custom assistance. We, as a reliable writing service, keep our users’ personal
            information confidential and protected.
          </p>
          <p>
            To buy essay papers for sale, you don’t need much time and effort. All you need to do is
            to send a request “please, write my personal statement for me!” As soon as the writer
            with the right qualification is assigned to your order, the process will be launched. We
            want to see you happy with the final result so we will do our best to please you. This
            means that you don’t need to bother yourself with anything apart from the organizational
            nuances and using our assistance the proper way. Now when you are looking for the best
            essays for sale, we are here to help you. Just give us an opportunity to prove our pure
            intentions to you!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Buy paper">
      Get your personalized essay
      <br />
      {' '}
      for sale
    </CTA>

  </Layout>
);

export default WriteForMePage;
